<template>
  <iframe :src="url" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
</template>
<script>
export default {
  name: 'Smartenergy',
  data() {
    return {
      url: 'https://datav.aliyuncs.com/share/d5be69fa7ac13cb523d26f3db943f11e?organ_id='+localStorage.getItem('organId')
    }
  },
  mounted() {
    
  }
}
</script>